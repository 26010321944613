import { on_ready, is_elementor_edit } from 'js/utils';

//if(document.body.contains('.elementor-editor-active')) return;

let dd_tab = document.querySelector('.dd-service-box');
let menu_bar_link = document.querySelector('.dd-service-menu');


export default function mega_menu_init() {
    mega_menu(); 
    add_view_all();
    close_button();
}

function mega_menu(){ 
    
    menu_bar_link.addEventListener("mouseover", () => {
        dd_tab.classList.add("active");
    });

    let other_link = document.querySelectorAll('.menu-item:not(.dd-service-menu)');
    other_link.forEach((e) => {
        e.addEventListener("mouseover", () => {
            dd_tab.classList.remove('active');
        }); 
    });
    
}

function add_view_all(){  
    let tab_heading = dd_tab.querySelector('.e-n-tabs-heading');

    const element = document.createElement('a');
    element.className = 'view-all';
    element.href = '/services';
    element.textContent = 'View All';

    tab_heading.append(element);

}

function close_button(){  
    let tab_heading = dd_tab.querySelector('.e-n-tabs-heading');

    const element = document.createElement('div');
    element.className = 'close-btn';  
    tab_heading.append(element);

    element.addEventListener("click", () => {
        dd_tab.classList.toggle("active");
    });

}